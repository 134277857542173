import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import { Label } from 'reactstrap'
import DatePicker from "react-datepicker";
import keyBy from 'lodash/keyBy';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Apis from '../../../../../api';
import IssueTable from './IssueTable';


const LegendWrapperStyle = { lineHeight: 4 }


const IssueGraph = ({ title, toDate, teamId, slug, teams, graphData = [], setGraphrefresh, graphrefresh }) => {
    const [apiResponse, setapiResonse] = React.useState([]);
    const [issueList, setIssueList] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false)
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [formData, setFormData] = React.useState({
        title: '',
        team: '',
        reported_date: new Date(),
        assigned_to: '',
        report: '',
        assigned_to: ''
    })
    const [subAdminList, setSubAdmin] = React.useState([])
    const [memberList, setMember] = React.useState([])

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    React.useEffect(() => {
        Apis.getSubAdmin().then((res) => {
            var response = res.data.data;
            setSubAdmin(response.subAdmin)
        }).catch((error) => {
            console.log(error.response)
        })
        Apis.getAllMember().then((res) => {
            setMember(res.data.data.member)
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    const memberByID = keyBy(memberList, '_id')
    const teamByID = keyBy(teams, '_id')

    React.useEffect(() => {
        const year = toDate.getFullYear();
        const fromDate = new Date(year, 0, 1);
        const toDates = new Date(year, 12, 0);
        let filters = [
            { type: slug },
            { reported_date: { $gte: new Date(fromDate).getTime() } },
            { reported_date: { $lte: new Date(toDates).getTime() } }
        ]
        if (teamId) {

            filters.push({ "team_id": teamId.value })
        }
        const raw = {
            "filter": filters
        }
        Apis.getDashboardIssue(raw).then((res) => {
            setapiResonse(res.data.data.dashboard_data)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh, toDate, teamId, slug])

    React.useEffect(() => {
        var list = []
        apiResponse && apiResponse.map((elm) => {
            var startDate = elm.reported_date
            var demo = {
                issue: elm.issue,
                issue_date: new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear(),
                team: teamByID[elm.team_id]?.name,
                assigned_to: memberByID[elm.assigned_to]?.first_name + ' ' + memberByID[elm.assigned_to]?.last_name,
                reported_by: memberByID[elm.reported_by]?.first_name + ' ' + memberByID[elm.reported_by]?.last_name,
                report: elm.report,
                title: elm.title

            }
            return list.push(demo)

        })
        setIssueList(list)
    }, [apiResponse, memberList, teams])



    const handleSave = (formData) => {

        if (formData.title === '') {
            setError({
                ...error,
                error: 'Title is required',
                color: 'error',
                open: true
            })
        } else if (formData.team === '') {
            setError({
                ...error,
                error: 'Team is required',
                color: 'error',
                open: true
            })
        } else if (formData.assigned_to === '') {
            setError({
                ...error,
                error: 'Assigned to is required',
                color: 'error',
                open: true
            })
        } else if (formData.report === '') {
            setError({
                ...error,
                error: 'Detailed Report is required',
                color: 'error',
                open: true
            })
        } else if (formData.reported_date === '') {
            setError({
                ...error,
                error: 'Issue Date is required',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                title: formData.title,
                report: formData.report,
                reported_by: user.id,
                assigned_to: formData.assigned_to.value,
                team_id: formData.team.value,
                reported_date: new Date(formData.reported_date).getTime(),
                type: slug
            }
            Apis.storeDashboardIssue(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setRefresh(!refresh)
                setGraphrefresh(!graphrefresh)
                setShow(false)
                setFormData({
                    title: '',
                    team: '',
                    reported_date: new Date(),
                    assigned_to: '',
                    report: '',
                    assigned_to: ''
                })
            }).catch((error) => {
                console.log(error)
            })

        }

    }

    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={() => setError({
                    ...error,
                    open: false,
                })}
            >
                <Alert
                    onClose={() => setError({
                        ...error,
                        open: false,
                    })}
                    severity={error.color}
                    sx={{ width: '20%' }}>
                    {error.error}
                </Alert>
            </Snackbar>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div class="mapbox project_addbtn ">
                                <h4 className="fs-3 fw-bolder "> {title + ' Issue Graph'}
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-body" style={{ height: '400px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={graphData}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip
                                                    formatter={(value, name) => [value ? `${Math.round(value)}` : `0`, name === 'no_incident' ? "No Incident" : 'Incident']}
                                                />
                                                <Legend
                                                    wrapperStyle={LegendWrapperStyle}
                                                    verticalAlign="top"
                                                    formatter={(value) => value === 'no_incident' ? "No Incident" : 'Incident'}
                                                />
                                                <Line type="monotone" dataKey="no_incident" stroke="#8884d8" activeDot={{ r: 8 }} />
                                                <Line type="monotone" dataKey="incident" stroke="#82ca9d" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12"></div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body customecard">

                            <div class="mapbox project_addbtn ">
                                <h4 className="fs-3 fw-bolder "> {title + ' Issue List'}
                                    <button className='btn btn-primary' onClick={() => setShow(!show)}>Add<i className="fas fa-plus"></i></button>
                                </h4>
                            </div>
                            <IssueTable
                                issueList={issueList}
                            />
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <Label >
                                Title
                            </Label>
                            <input
                                type="text"
                                id="title"
                                name='title'
                                value={formData.title}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    [event.target.name]: event.target.value,
                                })}
                                className="form-control"
                                placeholder='Enter Title'
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label >
                                Team
                            </Label>
                            <ReactSelect
                                placeholder="Select Team..."
                                options={teams && teams.map((i) => ({ value: i._id, label: i.name }))}
                                name='team'
                                value={formData.team}
                                onChange={(option, params) => setFormData({
                                    ...formData,
                                    [params.name]: option || '',
                                })}
                                isClearable
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Reported By
                            </Label>
                            <input
                                type="text"
                                id="reported_by"
                                name='reported_by'
                                className="form-control"
                                placeholder='Enter  Reported By'
                                value={user.name}
                                readOnly
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Assigned to
                            </Label>
                            <ReactSelect
                                placeholder="Select Assigned To"
                                options={subAdminList && subAdminList.map((i) => ({ value: i._id, label: i.first_name + ' ' + i.last_name }))}
                                name='assigned_to'
                                value={formData.assigned_to}
                                onChange={(option, params) => setFormData({
                                    ...formData,
                                    [params.name]: option || '',
                                })}
                                isClearable
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                            />
                        </div>
                        <div className="col-lg-6 mb-2">
                            <Label>
                                Issue Date
                            </Label>
                            <DatePicker
                                className="form-control"
                                name='reported_date'
                                maxDate={new Date()}
                                selected={formData.reported_date}
                                onChange={(date) => setFormData({
                                    ...formData,
                                    reported_date: date,
                                })}
                                dateFormat="dd/MMM/yyyy"

                            />
                        </div>
                        <div className="col-lg-12 mb-2">
                            <Label>
                                Detailed Report
                            </Label>
                            <textarea
                                className="form-control"
                                name="report"
                                placeholder="Leave Detailed Report"
                                value={formData.report}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    [event.target.name]: event.target.value,
                                })}></textarea>
                        </div>


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSave(formData)}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)} >Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default IssueGraph;