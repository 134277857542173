import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import IncidentGraph from './componets/IncidentGraph'
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'
import { Label } from 'reactstrap'
import { default as ReactSelect } from "react-select"
import DatePicker from "react-datepicker";

import swal from 'sweetalert';

function Dashboard() {
    const { teamList } = CollaboratorApi()
    const [teams, setTeams] = React.useState([])
    const [filters, setFilters] = React.useState({ team: '', date: new Date() })

    React.useEffect(() => {
        if (teamList.length > 0) {
            setTeams(teamList.team)
        }
    }, [teamList])

    const onTeamChange = (option, params) => {
        setFilters({
            ...filters,
            [params.name]: option || '',
        });
    };
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Dashboard | P2IC'></Header>
                <Sidebar title='dashboard' show=''></Sidebar>

                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Dashboard</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12 mb-4">
                                    <div className='container-fluid'>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <Label >
                                                    Team
                                                </Label>
                                                <ReactSelect
                                                    placeholder="Filter Team..."
                                                    options={teams && teams.map((i) => ({ value: i._id, label: i.name }))}
                                                    name='team'
                                                    value={filters.team}
                                                    onChange={onTeamChange}
                                                    isClearable
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <Label >
                                                    Choose Month & Year
                                                </Label>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={filters.date}
                                                    onChange={(date) => setFilters({
                                                        ...filters,
                                                        date: date,
                                                    })}
                                                    dateFormat="MMMM/yyyy"
                                                    showMonthYearPicker

                                                />
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className='col-lg-12 text-end '>
                                                            <button className='btn btn-danger' onClick={() => setFilters({ team: '', date: new Date() })}>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <IncidentGraph
                                                title="safety"
                                                toDate={filters.date}
                                                teamId={filters.team}

                                            />
                                        </div>
                                        <div className="col-lg-4">
                                             {/* <IncidentGraph
                                                title="quality"
                                                toDate={filters.date}
                                                teamId={filters.team}
                                            /> */}
                                        </div>
                                        <div className="col-lg-4">
                                           {/*  <IncidentGraph
                                                title="cost"
                                                toDate={filters.date}
                                                teamId={filters.team}
                                            /> */}
                                        </div>
                                        <div className="col-lg-12 border-top mb-4"></div>
                                        <div className="col-lg-4">
                                            {/* <IncidentGraph
                                                title="reliability"
                                                toDate={filters.date}
                                                teamId={filters.team}
                                            /> */}
                                        </div>
                                        <div className="col-lg-4">
                                            {/*  <IncidentGraph
                                                title="people"
                                                toDate={filters.date}
                                                teamId={filters.team}
                                            /> */}
                                        </div>
                                        <div className="col-lg-4">
                                            {/*  <IncidentGraph
                                                title="delivery"
                                                toDate={filters.date}
                                                teamId={filters.team}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <Footer></Footer>
                </div>

            </div>
        </>
    )
}

export default Dashboard
